import * as _jquery2 from "jquery";

var _jquery = "default" in _jquery2 ? _jquery2.default : _jquery2;

var exports = {};

(function (factory) {
  if (exports) {
    exports = factory(_jquery);
  } else {
    factory(jQuery);
  }
})(function ($) {
  /*
   * Translated default messages for the jQuery validation plugin.
   * Locale: DE (German, Deutsch)
   */
  $.extend($.validator.messages, {
    required: "Dieses Feld ist ein Pflichtfeld.",
    maxlength: $.validator.format("Geben Sie bitte maximal {0} Zeichen ein."),
    minlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
    rangelength: $.validator.format("Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein."),
    email: "Geben Sie bitte eine g\xFCltige E-Mail-Adresse ein.",
    url: "Geben Sie bitte eine g\xFCltige URL ein.",
    date: "Geben Sie bitte ein g\xFCltiges Datum ein.",
    number: "Geben Sie bitte eine Nummer ein.",
    digits: "Geben Sie bitte nur Ziffern ein.",
    equalTo: "Wiederholen Sie bitte denselben Wert.",
    range: $.validator.format("Geben Sie bitte einen Wert zwischen {0} und {1} ein."),
    max: $.validator.format("Geben Sie bitte einen Wert kleiner oder gleich {0} ein."),
    min: $.validator.format("Geben Sie bitte einen Wert gr\xF6\xDFer oder gleich {0} ein."),
    creditcard: "Geben Sie bitte eine g\xFCltige Kreditkarten-Nummer ein.",
    remote: "Korrigieren Sie bitte dieses Feld.",
    dateISO: "Geben Sie bitte ein g\xFCltiges Datum ein (ISO-Format).",
    step: $.validator.format("Geben Sie bitte ein Vielfaches von {0} ein."),
    maxWords: $.validator.format("Geben Sie bitte {0} W\xF6rter oder weniger ein."),
    minWords: $.validator.format("Geben Sie bitte mindestens {0} W\xF6rter ein."),
    rangeWords: $.validator.format("Geben Sie bitte zwischen {0} und {1} W\xF6rtern ein."),
    accept: "Geben Sie bitte einen Wert mit einem g\xFCltigen MIME-Typ ein.",
    alphanumeric: "Geben Sie bitte nur Buchstaben (keine Umlaute), Zahlen oder Unterstriche ein.",
    bankaccountNL: "Geben Sie bitte eine g\xFCltige Kontonummer ein.",
    bankorgiroaccountNL: "Geben Sie bitte eine g\xFCltige Bank- oder Girokontonummer ein.",
    bic: "Geben Sie bitte einen g\xFCltigen BIC-Code ein.",
    cifES: "Geben Sie bitte eine g\xFCltige CIF-Nummer ein.",
    cpfBR: "Geben Sie bitte eine g\xFCltige CPF-Nummer ein.",
    creditcardtypes: "Geben Sie bitte eine g\xFCltige Kreditkarten-Nummer ein.",
    currency: "Geben Sie bitte eine g\xFCltige W\xE4hrung ein.",
    extension: "Geben Sie bitte einen Wert mit einer g\xFCltigen Erweiterung ein.",
    giroaccountNL: "Geben Sie bitte eine g\xFCltige Girokontonummer ein.",
    iban: "Geben Sie bitte eine g\xFCltige IBAN ein.",
    integer: "Geben Sie bitte eine positive oder negative Nicht-Dezimalzahl ein.",
    ipv4: "Geben Sie bitte eine g\xFCltige IPv4-Adresse ein.",
    ipv6: "Geben Sie bitte eine g\xFCltige IPv6-Adresse ein.",
    lettersonly: "Geben Sie bitte nur Buchstaben ein.",
    letterswithbasicpunc: "Geben Sie bitte nur Buchstaben oder Interpunktion ein.",
    mobileNL: "Geben Sie bitte eine g\xFCltige Handynummer ein.",
    mobileUK: "Geben Sie bitte eine g\xFCltige Handynummer ein.",
    netmask: "Geben Sie bitte eine g\xFCltige Netzmaske ein.",
    nieES: "Geben Sie bitte eine g\xFCltige NIE-Nummer ein.",
    nifES: "Geben Sie bitte eine g\xFCltige NIF-Nummer ein.",
    nipPL: "Geben Sie bitte eine g\xFCltige NIP-Nummer ein.",
    notEqualTo: "Geben Sie bitte einen anderen Wert ein. Die Werte d\xFCrfen nicht gleich sein.",
    nowhitespace: "Kein Leerzeichen bitte.",
    pattern: "Ung\xFCltiges Format.",
    phoneNL: "Geben Sie bitte eine g\xFCltige Telefonnummer ein.",
    phonesUK: "Geben Sie bitte eine g\xFCltige britische Telefonnummer ein.",
    phoneUK: "Geben Sie bitte eine g\xFCltige Telefonnummer ein.",
    phoneUS: "Geben Sie bitte eine g\xFCltige Telefonnummer ein.",
    postalcodeBR: "Geben Sie bitte eine g\xFCltige brasilianische Postleitzahl ein.",
    postalCodeCA: "Geben Sie bitte eine g\xFCltige kanadische Postleitzahl ein.",
    postalcodeIT: "Geben Sie bitte eine g\xFCltige italienische Postleitzahl ein.",
    postalcodeNL: "Geben Sie bitte eine g\xFCltige niederl\xE4ndische Postleitzahl ein.",
    postcodeUK: "Geben Sie bitte eine g\xFCltige britische Postleitzahl ein.",
    require_from_group: $.validator.format("F\xFCllen Sie bitte mindestens {0} dieser Felder aus."),
    skip_or_fill_minimum: $.validator.format("\xDCberspringen Sie bitte diese Felder oder f\xFCllen Sie mindestens {0} von ihnen aus."),
    stateUS: "Geben Sie bitte einen g\xFCltigen US-Bundesstaat ein.",
    strippedminlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
    time: "Geben Sie bitte eine g\xFCltige Uhrzeit zwischen 00:00 und 23:59 ein.",
    time12h: "Geben Sie bitte eine g\xFCltige Uhrzeit im 12-Stunden-Format ein.",
    vinUS: "Die angegebene Fahrzeugidentifikationsnummer (VIN) ist ung\xFCltig.",
    zipcodeUS: "Die angegebene US-Postleitzahl ist ung\xFCltig.",
    ziprange: "Ihre Postleitzahl muss im Bereich 902xx-xxxx bis 905xx-xxxx liegen."
  });
  return $;
});

export default exports;